import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Book, Category, Problem } from '@/types'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import axios from 'axios'
import Cookies from 'js-cookie'
import { SERVER_URL } from '@/config'
import { useToast } from '@/components/ui/use-toast'


const ProblemForm = ({ problem, books, categories, closeDialog }: {
  problem: Problem,
  books: Book[],
  categories: Category[],
  closeDialog: () => void
}) => {
  const [selectedBook, setSelectedBook] = React.useState<Book | undefined>(undefined)
  const [selectedCategory, setSelectedCategory] = React.useState<Category | undefined>(undefined)

  const { toast } = useToast()

  const {
    register,
    handleSubmit,
  } = useForm<Problem & { book: Book, category: Category, image: FileList }>()
  const onSubmit: SubmitHandler<Problem & { book: Book, category: Category, image: FileList }> = async (data) => {
    data.book = selectedBook as Book
    data.category = selectedCategory as Category
    
    if (!data.question) {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please input a question`,
      })
      return;
    }
    if (!data.answer){ 
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please input an answer`,
      })
      return;
    }
    
    const formData = new FormData()
    formData.append('id', problem.id.toString())
    formData.append('question', data.question)
    formData.append('answer', data.answer)
    if (data.book) formData.append('book_id', data.book.id.toString())
    if (data.category) formData.append('category_id', data.category.id.toString())
    if (data.image.length > 0) formData.append('image', data.image[0])

    try {
      await axios.put(SERVER_URL + `/problems/${problem.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${Cookies.get('password')}`,
        },
      })
        .then(() => {
          toast({
            title: 'Success',
            description: `Problem #${problem.id} updated successfully`,
          })
          closeDialog()
        })
        .catch(error => {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: error.response.statusText,
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeBook = (value: string) => {
    const bookId = parseInt(value.replace('book', ''))
    const book = books.find(book => book.id === bookId)
    setSelectedBook(book)
  }

  const handleChangeCategory = (value: string) => {
    const categoryId = parseInt(value.replace('category', ''))
    const category = categories.find(category => category.id === categoryId)
    setSelectedCategory(category)
  }

  return (
    <form className={'flex flex-col gap-2'} onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-center justify-between space-x-2'>
        <Label htmlFor={'question'}>Question:</Label>
        <Input className={'w-3/4'} id={'question'} defaultValue={problem.question} {...register('question')}></Input>
      </div>
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'answer'}>Answer:</Label>
        <Textarea className={'w-3/4'} id={'answer'} defaultValue={problem.answer} {...register('answer')}></Textarea>
      </div>

      {/*Book select*/}
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'book'}>Book:</Label>
        <Select onValueChange={handleChangeBook} defaultValue={`book${problem.book.id}`}>
          <SelectTrigger id={'book'} className='w-3/4'>
            <SelectValue placeholder={'Select book'} />
          </SelectTrigger>
          <SelectContent>
            {
              books.map((book) => (
                <SelectItem key={book.id} value={`book${book.id}`}>{book.name}</SelectItem>
              ))
            }
          </SelectContent>
        </Select>
      </div>

      {/*Category select*/}
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'category'}>Category:</Label>
        <Select onValueChange={handleChangeCategory} defaultValue={`category${problem.category.id}`}>
          <SelectTrigger id={'category'} className='w-3/4'>
            <SelectValue placeholder={'Select category'} />
          </SelectTrigger>
          <SelectContent>
            {
              categories.map((category) => (
                <SelectItem key={category.id} value={`category${category.id}`}>{category.name}</SelectItem>
              ))
            }
          </SelectContent>
        </Select>
      </div>

      <div className={'w-full flex justify-center'}>
        <img className={'max-w-[200px]'} src={SERVER_URL + '/images/' + problem.image} alt='' />
      </div>

      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'image'}>Image:</Label>
        <Input type={'file'} accept={'image/*'} className={'w-3/4'} id={'image'} {...register('image')}></Input>
      </div>

      <Button type={'submit'}>Apply</Button>
    </form>
  )
}

export default ProblemForm