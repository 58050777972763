import React from 'react'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Category } from '@/types'
import axios from 'axios'
import { SERVER_URL } from '@/config'
import Cookies from 'js-cookie'

const AddCategoryForm = ({ closeDialog }: { closeDialog: () => void }) => {
  const { toast } = useToast()

  const { register, handleSubmit } = useForm<Category>()

  const onSubmit: SubmitHandler<Category> = async (data) => {
    if (!data.name) {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please input a category`,
      })
      return;
    }
    try {
      await axios.post(SERVER_URL + `/categories`, {
        name: data.name,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${Cookies.get('password')}`,
        },
      })
        .then(() => {
          toast({
            title: 'Success',
            description: `Category added successfully`,
          })
          closeDialog()
        })
        .catch(error => {
            toast({
              variant: 'destructive',
              title: 'Error',
              description: error.response.statusText,
            })
          },
        )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form className={'flex flex-col gap-2'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'name'}>Name:</Label>
        <Input className='w-3/4' id={'name'} {...register('name')}></Input>
      </div>
      <Button type={'submit'}>Add</Button>
    </form>
  )
}

export default AddCategoryForm