import { useCallback, useEffect, useState } from 'react'
import { Problem } from '@/types'
import axios from 'axios'
import { SERVER_URL } from '@/config'

export const useFetchProblems = ({ reload }: { reload: unknown }) => {
  const [problems, setProblems] = useState<Array<Problem>>([])
  const [loading, setLoading] = useState(false)

  const fetchProblems = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${SERVER_URL}/problems`)
      setProblems(data)
    } catch (error) {
      console.log(error)
      setProblems([])
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchProblems()
  }, [reload, fetchProblems])

  return { problems, loading }
}