import { useState } from 'react'
import { SERVER_URL } from '@/config'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import ProblemForm from '@/components/admin/forms/ProblemForm'
import { useFetchProblems } from '@/hooks/useFetchProblems'
import { useFetchBooks } from '@/hooks/useFetchBooks'
import { useFetchCategories } from '@/hooks/useFetchCategories'
import AddProblemForm from '@/components/admin/forms/AddProblemForm'
import { Trash2 } from 'lucide-react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useToast } from '@/components/ui/use-toast'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

const ProblemsTab = () => {
  const [reload, setReload] = useState<number[]>([])
  const { problems } = useFetchProblems({ reload })
  const { books } = useFetchBooks({ reload })
  const { categories } = useFetchCategories({ reload })
  const [openDialog, setOpenDialog] = useState(false)
  const { toast } = useToast()

  const handleDeleteProblem = async (id: number) => {
    try {
      await axios.delete(SERVER_URL + `/problems/${id}`, {
        headers: {
          'Authorization': `${Cookies.get('password')}`,
        }
      })
        .then(() => {
          toast({
            title: 'Success',
            description: `Problem #${id} deleted successfully`,
          })
          setReload(
            [
              ...reload,
              new Date().getTime()
            ]
          )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const closeDialog = () => {
    setOpenDialog(false)
    setReload(
      [
        ...reload,
        new Date().getTime()
      ]
    )
  }

  return (
    <>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button variant={'outline'}>Add new</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add new problem</DialogTitle>
          </DialogHeader>
          <AddProblemForm closeDialog={closeDialog} categories={categories} books={books} />
        </DialogContent>
      </Dialog>
      {
        problems.map((problem, index) => (
          <div key={problem.id}
            className={'flex gap-4 justify-between items-center px-2 py-1 bg-white rounded-md shadow-md max-w-[500px]  sm:flex-row flex-col'}>
            <div className={"flex gap-2 w-full"}>
              <div className={"relative w-[200px] overflow-hidden"}>
                <img className={"object-cover"} src={SERVER_URL + '/images/' + problem.image} alt='' />
              </div>
              <div className={"flex flex-col w-full"}>
                <p className={"text-lg font-bold"}>{index + 1}. {
                  problem.question.length > 26 ? problem.question.slice(0, 26) + '...' : problem.question
                }</p>
                <hr />
                <p>{
                  problem.answer.length > 80 ? problem.answer.slice(0, 80) + '...' : problem.answer
                }</p>
              </div>
            </div>
            <div className={"flex items-center gap-2 sm:flex-col"}>
              <Dialog>
                <DialogTrigger asChild>
                  <Button>Edit</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Problem #{problem.id}</DialogTitle>
                  </DialogHeader>
                  <ProblemForm problem={problem} books={books} categories={categories} closeDialog={closeDialog} />
                </DialogContent>
              </Dialog>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Trash2 cursor={'pointer'} color={'red'} />
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={async () => await handleDeleteProblem(problem.id)}>Delete</AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default ProblemsTab