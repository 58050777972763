import { ChangeEvent, useEffect, useState } from 'react'
import FlashcardList from '../components/FlashCardList'
import { Problem } from '@/types'
import { useFetchProblems } from '@/hooks/useFetchProblems'
import { useFetchBooks } from '@/hooks/useFetchBooks'
import { useFetchCategories } from '@/hooks/useFetchCategories'

export default function HomePage() {
  const [reload] = useState({})
  const { problems } = useFetchProblems({ reload })
  const { books } = useFetchBooks({ reload })
  const { categories } = useFetchCategories({ reload })

  const [flashcards, setFlashcards] = useState<Array<Problem>>([])

  const [problemNumber, setProblemNumber] = useState(0)
  const [currentCard, setCurrentCard] = useState<Problem>()

  const [bookId, setBookId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)

  useEffect(() => {
    setFlashcards(problems)
  }, [problems])

  useEffect(() => {
    setCurrentCard(flashcards[problemNumber])
  }, [problemNumber, flashcards])

  function nextCard() {
    if (flashcards.length > problemNumber + 1) {
      setProblemNumber(problemNumber + 1)
    } else {
      setProblemNumber(0)
    }
  }

  function previousCard() {
    if (problemNumber > 0) {
      setProblemNumber(problemNumber - 1)
    } else {
      setProblemNumber(flashcards.length - 1)
    }
  }

  function handleCategoryChange(
    e: ChangeEvent<HTMLSelectElement>,
  ) {
    e.preventDefault()
    const newId = parseInt(e.target.value)
    setCategoryId(newId)
    setProblemNumber(0)

    if (newId === 0) {
      setFlashcards(problems)
    } else {
      const filteredResults: Array<Problem> = []
      problems.forEach((problem) => {
        if (problem.category.id === newId) {
          filteredResults.push(problem)
        }
      })

      setFlashcards(filteredResults)
    }

    setBookId(0)
  }

  function handleBookChange(
    e: ChangeEvent<HTMLSelectElement>,
  ) {
    e.preventDefault()
    setProblemNumber(0)
    const newId = parseInt(e.target.value)
    setBookId(newId)

    if (newId === 0) {
      setFlashcards(problems)
    } else {
      const filteredResults: Array<Problem> = []
      problems.forEach((problem) => {
        if (problem.book.id === newId) {
          filteredResults.push(problem)
        }
      })

      setFlashcards(filteredResults)
    }

    setCategoryId(0)
  }

  return (
    <>
      <form className='header'>
        <div className='form-group'>
          <label htmlFor='category'>Category</label>
          <select
            className='border'
            id='category'
            value={categoryId}
            onChange={handleCategoryChange}
          >
            <option value='0'>All Categories</option>
            {categories.map(category => {
              return <option key={`category-${category.id}`} value={category.id}>{category.name}</option>
            })}
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='book'>Book</label>
          <select
            className='border'
            id='book'
            value={bookId}
            onChange={handleBookChange}
          >
            <option value={0}>All Books</option>
            {books.map(book => {
              return <option key={`book-${book.id}`} value={book.id}>{book.name}</option>
            })}
          </select>
        </div>

        <div className='form-group'></div>
      </form>
      <div className='container'>
        {currentCard && <FlashcardList currentCard={currentCard} />}
      </div>
      <div className='carousel-buttons'>
        <button className='btn' onClick={previousCard}>
          Previous
        </button>
        <button className='btn' onClick={nextCard}>
          Next
        </button>
      </div>
    </>
  )
}