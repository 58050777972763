import React from 'react'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Book } from '@/types'
import axios from 'axios'
import { SERVER_URL } from '@/config'
import Cookies from 'js-cookie'
import { useToast } from '@/components/ui/use-toast'

const AddBookForm = ({ closeDialog }: { closeDialog: () => void }) => {
  const { toast } = useToast()

  const { register, handleSubmit } = useForm<Book>()

  const onSubmit: SubmitHandler<Book> = async (data) => {
    try {
      await axios.post(SERVER_URL + `/books`, {
        name: data.name,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${Cookies.get('password')}`,
        },
      })
        .then(() => {
          toast({
            title: 'Success',
            description: `Book added successfully`,
          })
          closeDialog()
        })
        .catch(error => {
            toast({
              variant: 'destructive',
              title: 'Error',
              description: error.response.statusText,
            })
          },
        )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form className={'flex flex-col gap-2'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'name'}>Name:</Label>
        <Input className='w-3/4' id={'name'} {...register('name')}></Input>
      </div>
      <Button type={'submit'}>Add</Button>
    </form>
  )
}

export default AddBookForm