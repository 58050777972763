import { useEffect, useState } from 'react'
import { Problem } from '@/types'
import { SERVER_URL } from '@/config'

export default function FlashCard({ currentCard }: { currentCard: Problem }) {
  const [flip, setFlip] = useState(false);
  const [nextCard, setNextCard] = useState(false);
  useEffect(() => {
    if (flip) {
      setFlip(false);
      setNextCard(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard]);

  return (
    <div
      className={`card ${flip ? 'flip' : ''} ${nextCard ? 'noFlip': ''}`}
      onClick={(e) => {
        setFlip(!flip)
        setNextCard(false)
      }}
    >
      <div className='front'>
        <p>{currentCard.question}</p>
        <p className='book'>
          {currentCard.bookInvisible === true ? <p></p> : currentCard.book?.name}
        </p>
      </div>
      <div className='back'>
        <div
          className='back-image'
          style={{
            backgroundImage: `url(${SERVER_URL}/images/${currentCard.image})`
          }}
          id='back'
        ></div>
        <div className='answer-placeholder'>
          <p>{currentCard.answer}</p>
          <p className='category'>{currentCard.category?.name}</p>
        </div>
      </div>
    </div>
  )
}
