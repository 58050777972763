import FlashCard from './FlashCard'
import { Problem } from '@/types'

export default function FlashcardList ({ currentCard }: { currentCard: Problem }) {
  return (
    <div className='card-grid'>
      <FlashCard currentCard={currentCard} />
    </div>
  )
}
