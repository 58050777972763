import { Route, Routes } from 'react-router-dom'
import './App.css'
import HomePage from '@/pages'
import AdminPage from '@/pages/admin'

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/admin' element={<AdminPage />} />
    </Routes>
  )
}

export default App
