import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import React, { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import Cookies from 'js-cookie'
import { Toaster } from '@/components/ui/toaster'
import BooksTab from '@/components/admin/tabs/BooksTab'
import CategoriesTab from '@/components/admin/tabs/CategoriesTab'
import ProblemsTab from '@/components/admin/tabs/ProblemsTab'


export default function AdminPage() {
  const [password, setPassword] = useState('')
  const [isPasswordApplied, setIsPasswordApplied] = useState(false)

  const applyPassword = () => {
    Cookies.set('password', password)
    setIsPasswordApplied(true)
  }

  const handleChangePassword = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPassword(e.target.value)
  }

  return (
    <>
      <Toaster />
      {
        isPasswordApplied ? (
          <div className={'flex justify-center w-full px-[2%] md:px-[2%] lg:px-[5%] xl:px-[10%] py-6'}>
            <Tabs defaultValue='categories' className='w-max max-w-full bg-gray-50 rounded-md p-2'>
              <TabsList className={'justify-around w-full'}>
                <TabsTrigger value='categories'>Categories</TabsTrigger>
                <TabsTrigger value='books'>Books</TabsTrigger>
                <TabsTrigger value='problems'>Problems</TabsTrigger>
              </TabsList>
              <TabsContent value='categories'>
                <div className={'flex flex-col gap-2 max-h-[600px] overflow-y-auto'}>
                 <CategoriesTab />
                </div>
              </TabsContent>
              <TabsContent value='books'>
                <div className={'flex flex-col gap-2 max-h-[600px] overflow-y-auto'}>
                  <BooksTab />
                </div>
              </TabsContent>
              <TabsContent value='problems'>
                <div className={'flex flex-col gap-2 max-h-[600px] overflow-y-auto'}>
                  <ProblemsTab />
                </div>
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <div className={'w-full h-screen flex flex-col gap-6 justify-center items-center'}>
            <form onSubmit={applyPassword} className={'flex flex-col gap-2 w-full px-[2%] sm:px-[10%] md:px-[20%] lg:px-[30%]'}>
              <Input placeholder={'Password'} onChange={handleChangePassword} />
              <Button type='submit' variant='default'>Apply</Button>
            </form>
          </div>
        )
      }
    </>
  )
};