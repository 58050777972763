import { useCallback, useEffect, useState } from 'react';
import { Category } from '@/types';
import axios from 'axios';
import { SERVER_URL } from '@/config';

export const useFetchCategories = ({ reload }: { reload: unknown }) => {
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [loading, setLoading] = useState(false);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${SERVER_URL}/categories`);
      setCategories(data);
    } catch (error) {
      console.log(error);
      setCategories([]);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [reload, fetchCategories]);

  return { categories, loading };
}