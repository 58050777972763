import React from 'react'
import { useToast } from '@/components/ui/use-toast'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Book, Category, Problem } from '@/types'
import axios from 'axios'
import { SERVER_URL } from '@/config'
import Cookies from 'js-cookie'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Button } from '@/components/ui/button'

const AddProblemForm = ({ closeDialog, categories, books }: { closeDialog: () => void, categories: Category[], books: Book[] }) => {
  const { toast } = useToast()
  const [selectedBook, setSelectedBook] = React.useState<Book | undefined>(undefined)
  const [selectedCategory, setSelectedCategory] = React.useState<Category | undefined>(undefined)

  const { register, handleSubmit } = useForm<Problem & { book: Book, category: Category, image: FileList }>()

  const onSubmit: SubmitHandler<Problem & { book: Book, category: Category, image: FileList }> = async (data) => {
    data.book = selectedBook as Book
    data.category = selectedCategory as Category
    if (!data.question) {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please input a question`,
      })
      return;
    }
    if (!data.answer){ 
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please input an answer`,
      })
      return;
    }    
    const formData = new FormData()
    formData.append('question', data.question)
    formData.append('answer', data.answer)
    if (data.book) formData.append('book_id', data.book.id.toString())
    else {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please select a book`,
      })
      return;
    } 
    if (data.category) formData.append('category_id', data.category.id.toString())
    else {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please select a category`,
      })
      return;
    }
    if (data.image.length > 0) formData.append('image', data.image[0])
    else {
      toast({
        title: 'Failed',
        variant: 'destructive',
        description: `Please select an image`,
      })
      return;
    }
    try {
      await axios.post(SERVER_URL + `/problems`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${Cookies.get('password')}`,
        },
      })
        .then(() => {
          toast({
            title: 'Success',
            description: `Problem added successfully`,
          })
          closeDialog()
        })
        .catch(error => {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: error.response.statusText,
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeBook = (value: string) => {
    const bookId = parseInt(value.replace('book', ''))
    const book = books.find(book => book.id === bookId)
    setSelectedBook(book)
  }

  const handleChangeCategory = (value: string) => {
    const categoryId = parseInt(value.replace('category', ''))
    const category = categories.find(category => category.id === categoryId)
    setSelectedCategory(category)
  }

  return (
    <form className={'flex flex-col gap-2'} onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-center justify-between space-x-2'>
        <Label htmlFor={'question'}>Question:</Label>
        <Input className={'w-3/4'} id={'question'} {...register('question')}></Input>
      </div>
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'answer'}>Answer:</Label>
        <Textarea className={'w-3/4'} id={'answer'} {...register('answer')}></Textarea>
      </div>

      {/*Book select*/}
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'book'}>Book:</Label>
        <Select onValueChange={handleChangeBook}>
          <SelectTrigger id={'book'} className='w-3/4'>
            <SelectValue placeholder={'Select book'} />
          </SelectTrigger>
          <SelectContent>
            {
              books.map((book) => (
                <SelectItem key={book.id} value={`book${book.id}`}>{book.name}</SelectItem>
              ))
            }
          </SelectContent>
        </Select>
      </div>

      {/*Category select*/}
      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'category'}>Category:</Label>
        <Select onValueChange={handleChangeCategory}>
          <SelectTrigger id={'category'} className='w-3/4'>
            <SelectValue placeholder={'Select category'} />
          </SelectTrigger>
          <SelectContent>
            {
              categories.map((category) => (
                <SelectItem key={category.id} value={`category${category.id}`}>{category.name}</SelectItem>
              ))
            }
          </SelectContent>
        </Select>
      </div>

      <div className={'flex items-center justify-between gap-2'}>
        <Label htmlFor={'image'}>Image:</Label>
        <Input type={'file'} accept={'image/*'} className={'w-3/4'} id={'image'} {...register('image')}></Input>
      </div>

      <Button type={'submit'}>Add</Button>
    </form>
  )
}

export default AddProblemForm