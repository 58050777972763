import { useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import CategoryForm from '@/components/admin/forms/CategoryForm'
import { useFetchCategories } from '@/hooks/useFetchCategories'
import AddCategoryForm from '@/components/admin/forms/AddCategoryForm'
// import { Trash2 } from 'lucide-react'

const CategoriesTab = () => {
  const [reload, setReload] = useState<number[]>([])
  const { categories } = useFetchCategories({ reload })
  const [openDialog, setOpenDialog] = useState(false)

  const closeDialog = () => {
    setOpenDialog(false)
    setReload(
      [
        ...reload,
        new Date().getTime()
      ]
    )
  }

  return (
    <>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button variant={'outline'}>Add new</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add new category</DialogTitle>
          </DialogHeader>
          <AddCategoryForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
      {
        categories.map((category, index) => (
          <div key={category.id}
               className={'flex flex-row gap-4 justify-between items-center px-2 py-1 bg-white rounded-md shadow-md'}>
            <p>{index + 1}. {category.name}</p>
            <div className={"flex items-center gap-2"}>
              <Dialog>
                <DialogTrigger asChild>
                  <Button>Edit</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>{category.name}</DialogTitle>
                  </DialogHeader>
                  <CategoryForm category={category} closeDialog={closeDialog} />
                </DialogContent>
              </Dialog>
              {/* <Trash2 cursor={'pointer'} color={'red'} /> */}
            </div>
          </div>
        ))
      }
    </>
  )
}

export default CategoriesTab