import { useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import AddBookForm from '@/components/admin/forms/AddBookForm'
import BookForm from '@/components/admin/forms/BookForm'
// import { Trash2 } from 'lucide-react'
import { useFetchBooks } from '@/hooks/useFetchBooks'

const BooksTab = () => {
  const [reload, setReload] = useState<number[]>([])
  const { books } = useFetchBooks({ reload })
  const [dialogOpen, setDialogOpen] = useState(false)

  const closeDialog = () => {
    setDialogOpen(false)
    setReload(
      [
        ...reload,
        new Date().getTime()
      ]
    )
  }

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogTrigger asChild>
          <Button variant={'outline'}>Add new</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add new book</DialogTitle>
          </DialogHeader>
          <AddBookForm closeDialog={closeDialog} />
        </DialogContent>
      </Dialog>
      {
        books.map((book, index) => (
          <div key={book.id}
               className={'flex flex-row gap-4 justify-between items-center px-2 py-1 bg-white rounded-md shadow-md'}>
            <p>{index + 1}. {book.name}</p>
            <div className={'flex items-center gap-2'}>
              <Dialog>
                <DialogTrigger asChild>
                  <Button>Edit</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>{book.name}</DialogTitle>
                  </DialogHeader>
                  <BookForm book={book} closeDialog={closeDialog} />
                </DialogContent>
              </Dialog>
              {/* <Trash2 cursor={'pointer'} color={'red'} /> */}
            </div>
          </div>
        ))
      }
    </>
  )
}

export default BooksTab