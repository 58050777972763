import { useCallback, useEffect, useState } from 'react';
import { Book } from '@/types';
import axios from 'axios';
import { SERVER_URL } from '@/config';

export const useFetchBooks = ({ reload }: { reload: unknown }) => {
  const [books, setBooks] = useState<Array<Book>>([]);
  const [loading, setLoading] = useState(false);

  const fetchBooks = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${SERVER_URL}/books`);
      setBooks(data);
    } catch (error) {
      console.log(error);
      setBooks([]);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchBooks();
  }, [reload, fetchBooks]);

  return { books, loading };
}